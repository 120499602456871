@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #202126;
}

body {
  line-height: 1.5;
  font-family: 'Inter', 'Open Sans', 'Roboto', sans-serif;
  font-display: swap;
  font-weight: normal;
  color: #ffffff;
  width: 100vw;
  overflow-x: hidden;
}

/* 
::-webkit-scrollbar {
  width: 12px;
}*/

/* Track */
/* ::-webkit-scrollbar-track {
  background: $keet-bg1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: $keet-bg2;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #0b0a0b;
}  */

.btn:hover {
  /* @apply border border-[#d9554b] bg-red2 */
  box-shadow: 0px 0px 32px rgba(237, 105, 95, 0.5);
}

.terms p,
.terms li {
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: justify;
}

.terms ul {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
}

.terms li {
  margin-left: 12px;
}

.terms h3 {
  font-size: 1.2rem;
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: 600;
  scroll-margin-top: 100px;
}

.terms h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  font-style: italic;
  scroll-margin-top: 100px;
}

.terms a:hover {
  color: #86c8b4;
}

.terms .content a:hover {
  color: #ffffff;
}

.video-react,
.video-react-poster {
  background-color: rgba(20, 25, 37, 0) !important;
}

.video-react,
.video-react *,
.video-react-play-progress,
.video-react-play-progress::after,
.video-react-play-progress::before,
.video-react-poster {
  font-family: 'Open Sans', 'Roboto', sans-serif !important;
}

/* video-react-progress-control */
