@font-face {
  font-family: 'Overpass';
  font-weight: 400;
  font-style: normal;
  src: url(../../public/fonts/Overpass-Regular.ttf);
}

@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  font-style: normal;
  src: url(../../public/fonts/Overpass-Bold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url(../../public/fonts/OpenSans-Light.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url(../../public/fonts/OpenSans-LightItalic.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url(../../public/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url(../../public/fonts/OpenSans-Italic.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url(../../public/fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url(../../public/fonts/OpenSans-SemiBoldItalic.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url(../../public/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url(../../public/fonts/OpenSans-BoldItalic.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url(../../public/fonts/OpenSans-ExtraBold.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: italic;
  src: url(../../public/fonts/OpenSans-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  src: url(../../public/fonts/Inter-Thin.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: normal;
  src: url(../../public/fonts/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url(../../public/fonts/Inter-Light.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url(../../public/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url(../../public/fonts/Inter-Medium.ttf);
}


@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url(../../public/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url(../../public/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: url(../../public/fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url(../../public/fonts/Inter-Black.ttf);
}
